import Permissions from 'src/security/permissions';
const permissions = Permissions.values;

const adminRoutes = [
  {
    path: '/admin',
    i18n: 'dashboard.menu',
    loader: () =>
      import('src/view/admin/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/admin/person-name-breadcrumb',
    collapseName: 'my-profile',
    i18n: 'roles.admin.label',
    parent: '/admin',
    redirect: '/admin/profile',
    permissionRequired: null,
    virtual: true,
  },

  {
    path: '/admin/profile',
    collapseName: 'my-profile',
    i18n: 'auth.profile.title',
    parent: '/admin/person-name-breadcrumb',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/admin/password-change',
    collapseName: 'my-profile',
    i18n: 'auth.passwordChange.title',
    parent: '/admin/person-name-breadcrumb',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/admin/admin',
    i18n: 'user.menu',
    collapseName: 'my-profile',
    parent: '/admin/person-name-breadcrumb',
    loader: () =>
      import('src/view/admin/admin/list/AdminPage'),
    permissionRequired: permissions.adminRead,
    exact: true,
  },

  {
    path: '/admin/admin/new',
    i18n: 'user.new.title',
    collapseName: 'my-profile',
    parent: '/admin/admin',
    loader: () =>
      import('src/view/admin/admin/new/AdminNewPage'),
    permissionRequired: permissions.adminCreate,
    exact: true,
  },

  {
    path: '/admin/admin/:id/edit',
    i18n: 'user.edit.title',
    collapseName: 'my-profile',
    parent: '/admin/admin',
    loader: () =>
      import('src/view/admin/admin/edit/AdminEditPage'),
    permissionRequired: permissions.adminEdit,
    exact: true,
  },

  {
    path: '/admin/admin/:id',
    i18n: 'user.view.title',
    collapseName: 'my-profile',
    parent: '/admin/admin',
    loader: () =>
      import('src/view/admin/admin/view/AdminViewPage'),
    permissionRequired: permissions.adminRead,
    exact: true,
  },

  {
    path: '/admin/audit-log',
    i18n: 'auditLog.menu',
    parent: '/admin',
    loader: () =>
      import('src/view/admin/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
    exact: true,
  },

  {
    path: '/admin/customer',
    i18n: 'customer.menu',
    parent: '/admin',
    loader: () =>
      import('src/view/admin/customer/list/CustomerPage'),
    permissionRequired: permissions.customerRead,
    exact: true,
  },

  {
    path: '/admin/customer/new',
    i18n: 'customer.new.title',
    parent: '/admin/customer',
    loader: () =>
      import(
        'src/view/admin/customer/form/CustomerFormPage'
      ),
    permissionRequired: permissions.customerCreate,
    exact: true,
  },

  {
    path: '/admin/customer/:id/edit',
    i18n: 'customer.edit.title',
    parent: '/admin/customer',
    loader: () =>
      import(
        'src/view/admin/customer/form/CustomerFormPage'
      ),
    permissionRequired: permissions.customerEdit,
    exact: true,
  },

  {
    path: '/admin/customer/:id/verify',
    i18n: 'customer.verification.title',
    parent: '/admin/customer',
    loader: () =>
      import(
        'src/view/admin/customer/view/CustomerViewPage'
      ),
    permissionRequired: permissions.customerEdit,
  },

  {
    path: '/admin/property',
    i18n: 'property.menu',
    parent: '/admin',
    loader: () =>
      import(
        'src/view/admin/property/list/PropertyListPage'
      ),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },
  {
    path: '/admin/property/new',
    i18n: 'property.new.title',
    parent: '/admin/property',
    loader: () =>
      import(
        'src/view/admin/property/form/PropertyFormPage'
      ),
    permissionRequired: permissions.propertyCreate,
    exact: true,
  },
  {
    path: '/admin/property/importer',
    i18n: 'property.importer.title',
    parent: '/admin/property',
    loader: () =>
      import(
        'src/view/admin/property/importer/PropertyImporterPage'
      ),
    permissionRequired: permissions.propertyImport,
    exact: true,
  },
  {
    path: '/admin/property/:id/edit',
    i18n: 'property.edit.title',
    parent: '/admin/property',
    loader: () =>
      import(
        'src/view/admin/property/form/PropertyFormPage'
      ),
    permissionRequired: permissions.propertyEdit,
    exact: true,
  },
  {
    path: '/admin/property/:id',
    i18n: 'property.view.title',
    parent: '/admin/property',
    loader: () =>
      import(
        'src/view/admin/property/view/PropertyViewPage'
      ),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },

  {
    path: '/admin/fiat',
    i18n: 'fiat.title',
    parent: '/admin',
    loader: () =>
      import(
        'src/view/admin/fiat/list/FIATPage'
      ),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },
  {
    path: '/admin/fiat/:id',
    i18n: 'fiat.view.title',
    parent: '/admin/fiat',
    loader: () =>
      import(
        'src/view/admin/fiat/view/FIATViewPage'
      ),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },

  {
    path: '/admin/marketplace',
    i18n: 'marketplace.menu',
    parent: '/admin',
    loader: () =>
      import(
        'src/view/admin/marketplace/MarketplacePage'
      ),
    permissionRequired: permissions.transactionRead,
    exact: true,
  },
].filter(Boolean);

const customerRoutes = [
  {
    path: '/customer',
    i18n: 'dashboard.menu',
    loader: () => import('src/view/customer/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/person-name-breadcrumb',
    collapseName: 'my-profile',
    i18n: 'roles.customer.label',
    parent: '/customer',
    redirect: '/customer/profile',
    permissionRequired: null,
    virtual: true,
  },

  {
    path: '/customer/profile',
    collapseName: 'my-profile',
    i18n: 'auth.profile.title',
    parent: '/customer/person-name-breadcrumb',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/password-change',
    collapseName: 'my-profile',
    i18n: 'auth.passwordChange.title',
    parent: '/customer/person-name-breadcrumb',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/verification',
    collapseName: 'my-profile',
    i18n: 'customer.verification.menu',
    parent: '/customer/person-name-breadcrumb',
    loader: () =>
      import(
        'src/view/customer/verification/VerificationPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/investment',
    i18n: 'customer.investment.menu',
    parent: '/customer',
    loader: () =>
      import('src/view/customer/InvestmentPage'),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },

  {
    path: '/customer/wallet',
    i18n: 'customer.transaction.menu',
    parent: '/customer',
    loader: () =>
      import(
        'src/view/customer/transaction/TransactionPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/property',
    i18n: 'customer.property.menu',
    parent: '/customer',
    loader: () => import('src/view/customer/PropertyPage'),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },

  {
    path: '/customer/property/:id',
    i18n: 'customer.propertyDetail.menu',
    parent: '/customer/property',
    loader: () => import('src/view/home/propertyComponents/PropertyDetail'),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },

  {
    path: '/customer/khipu-success',
    i18n: 'customer.khipu.success',
    parent: '/customer/property',
    loader: () => import('src/view/customer/khipu/KhipuSuccessPage'),
    permissionRequired: null,
    exact: true
  },

  {
    path: '/customer/khipu-cancel',
    i18n: 'customer.khipu.cancel',
    parent: '/customer/property',
    loader: () => import('src/view/customer/khipu/KhipuCancelPage'),
    permissionRequired: null,
    exact: true
  },

  {
    path: '/customer/khipu-confirm',
    i18n: 'customer.khipu.confirm',
    parent: '/customer/property',
    loader: () => import('src/view/customer/khipu/KhipuConfirmPage'),
    permissionRequired: null,
    exact: true
  },

  {
    path: '/customer/marketplace',
    i18n: 'customer.marketplace.menu',
    parent: '/customer',
    loader: () =>
      import('src/view/customer/marketplace/MarketplacePage'),
    permissionRequired: permissions.propertyRead,
    exact: true,
  },

  {
    path: '/customer/calc',
    i18n: 'customer.contact.calc',
    parent: '/customer',
    loader: () => import('src/view/home/sectionComponents/Calc'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/contact',
    i18n: 'customer.contact.menu',
    parent: '/customer',
    loader: () => import('src/view/customer/ContactPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/customer/faq',
    i18n: 'common.help',
    parent: '/customer',
    loader: () => import('src/view/customer/FAQPage'),
    permissionRequired: null,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/admin/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/admin/auth/signup',
    loader: () => import('src/view/home/ComingSoonPage'),
  },
  {
    path: '/admin/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
  {
    path: '/',
    loader: () => import('src/view/home/HomePage'),
  },
  {
    path: '/home',
    loader: () => import('src/view/home/HomePage'),
  },
  {
    path: '/about',
    loader: () => import('src/view/home/AboutPage'),
  },
  {
    path: '/marketplace',
    loader: () => import('src/view/home/ComingSoonPage'),
  },
  {
    path: '/legal',
    loader: () => import('src/view/home/LegalPage'),
  },
  {
    path: '/calc',
    loader: () => import('src/view/home/CalcPage'),
  },
  {
    path: '/contact',
    loader: () => import('src/view/home/ContactPage'),
  },
  {
    path: '/signin-hide',
    loader: () => import('src/view/home/LoginPage'),
  },
  {
    path: '/signin',
    loader: () => import('src/view/home/LoginPage'),
  },
  {
    path: '/properties',
    loader: () => import('src/view/home/PropertyListPage'),
  },
  {
    path: '/property/:id',
    loader: () => import('src/view/home/PropertyViewPage'),
  },
  {
    path: '/signup',
    loader: () => import('src/view/home/RegisterPage'),
  },
  {
    path: '/news',
    loader: () => import('src/view/home/BlogListPage'),
  },
  {
    path: '/blog-details-one',
    loader: () => import('src/view/home/BlogGuidePage'),
  },
  {
    path: '/blog-details-two',
    loader: () => import('src/view/home/BlogDiscoverPage'),
  },
  {
    path: '/blog-details-three',
    loader: () =>
      import('src/view/home/BlogFinancialPage'),
  },
  {
    path: '/blog-details-four',
    loader: () => import('src/view/home/BlogBlockchainPage'),
  },
  {
    path: '/blog-details-five',
    loader: () => import('src/view/home/BlogFintechLatamPage'),
  },
  {
    path: '/blog-details-six',
    loader: () => import('src/view/home/BlogPixAnounPage'),
  },
  {
    path: '/blog-details-seven',
    loader: () => import('src/view/home/BlogWorldTokenized'),
  },
  {
    path: '/blog-details-eight',
    loader: () => import('src/view/home/BlogInterestRates'),
  },
  {
    path: '/blog-details-nine',
    loader: () => import('src/view/home/BlogRadioLaClave'),
  },
  {
    path: '/blog-details-ten',
    loader: () => import('src/view/home/BlogAmericaRetail'),
  },
  {
    path: '/blog-details-eleven',
    loader: () => import('src/view/home/BlogCriptonoticias'),
  },
  {
    path: '/blog-details-twelve',
    loader: () => import('src/view/home/BlogRadioUsach'),
  },
  {
    path: '/faq',
    loader: () => import('src/view/home/FAQPage'),
  },
  {
    path: '/widget',
    loader: () => import('src/view/widget/widgetPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/admin/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/admin/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/home/Error404Page'),
  },
].filter(Boolean);

export default {
  adminRoutes,
  customerRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};

export function findRoute(url = null, routes = []) {
  return (
    !!url &&
    (routes.find((route) => url === route.path) ||
      routes.find(
        (route) =>
          /\/:[\w\d_-]+/g.test(route.path) &&
          new RegExp(
            `^${route.path.replace(
              /:[\w\d_-]+/g,
              '[\\w\\d]+',
            )}$`,
          ).test(url),
      ))
  );
}

export function matchedAdminRoutes(
  url = null,
  exactOnly = false,
) {
  if (url === null || url === undefined) {
    return null;
  }

  let routes = [];

  const searchRouteStack = (url, exactOnly) => {
    const found = findRoute(url, adminRoutes);

    if (exactOnly === true) {
      return found;
    }

    if (found) {
      routes.push(found);
      if (found.parent && found.parent !== '/admin') {
        return searchRouteStack(found.parent, exactOnly);
      }
    }

    routes.reverse();

    return routes;
  };

  return searchRouteStack(url, exactOnly);
}

export function matchedCustomerRoutes(
  url = null,
  exactOnly = false,
) {
  if (url === null || url === undefined) {
    return null;
  }

  let routes = [];

  const searchRouteStack = (url, exactOnly) => {
    const found = findRoute(url, customerRoutes);

    if (exactOnly === true) {
      return found;
    }

    if (found) {
      routes.push(found);
      if (found.parent && found.parent !== '/customer') {
        return searchRouteStack(found.parent, exactOnly);
      }
    }

    routes.reverse();

    return routes;
  };

  return searchRouteStack(url, exactOnly);
}
